<template>
  <AppView>
    <!-- content @s -->
    <div class="nk-content nk-content-fluid">
      <div class="container-xl wide-xl">
        <div class="nk-content-inner">
          <div class="nk-content-body">
            <div class="nk-block-head nk-block-head-sm">
              <div class="nk-block-between g-3">
                <div class="nk-block-head-content">
                  <h3 class="nk-block-title page-title">
                    Configuración de la plataforma
                  </h3>
                </div>
              </div>
            </div>
            <!-- .nk-block-head -->
            <div class="nk-block">
              <div class="card card-bordered">
                <div class="card-aside-wrap">
                  <div class="card-content">
                    <ul class="nav nav-tabs nav-tabs-mb-icon nav-tabs-card">
                      <li class="nav-item">
                        <a class="nav-link active" href="#"
                          ><em class="icon ni ni-user-circle"></em
                          ><span>General</span></a
                        >
                      </li>
                    </ul>
                    <!-- .nav-tabs -->
                    <form @submit.prevent="save">
                      <div class="card-inner">
                        <div class="nk-block">
                          <div class="nk-block-head">
                            <h5 class="title">
                              Configuración de la plataforma
                            </h5>
                            <p>
                              Datos básicos como logo, nombre de la plataforma,
                              etc.
                            </p>
                          </div>

                          <div class="profile-ud-list">
                            <div class="profile-ud-item">
                              <div class="profile-ud wider">
                                <span class="profile-ud-label">Nombre</span>
                                <span class="profile-ud-value">
                                  <input
                                    type="text"
                                    class="form-control"
                                    autocomplete="nope"
                                    v-model.lazy="data.nombre"
                                    required
                                  />
                                </span>
                              </div>
                            </div>
                            <div class="profile-ud-item">
                              <div class="profile-ud wider">
                                <span class="profile-ud-label"
                                  >RFC</span
                                >
                                <span class="profile-ud-value">
                                  <input
                                    type="text"
                                    class="form-control"
                                    autocomplete="nope"
                                    v-model.lazy="data.rfc"
                                    required
                                  />
                                </span>
                              </div>
                            </div>
                            <div class="profile-ud-item">
                              <div class="profile-ud wider">
                                <span class="profile-ud-label"
                                  >Color</span
                                >
                                <span class="profile-ud-value">
                                  <input
                                    type="color"
                                    class="form-control"
                                    autocomplete="nope"
                                    v-model.lazy="data.color"
                                    required
                                  />
                                </span>
                              </div>
                            </div>
                          </div>

                          <div class="row">
                            <div class="col-lg-6 col-md-6 col-sm-12">
                              <div class="form-group">
                                <label class="form-label"
                                  >Logo para fondos blancos</label
                                >
                                <Uploader
                                  :preFiles="
                                    $filters.getFiles(
                                      data.f_logo_blanco?.id_archivo
                                    )
                                  "
                                  :server="
                                    $filters.getServerConfiguracion(model)
                                  "
                                  v-model:file="data.f_logo_blanco"
                                ></Uploader>
                              </div>
                            </div>
                            <div class="col-lg-6 col-md-6 col-sm-12">
                              <div class="form-group">
                                <label class="form-label"
                                  >Logo para fondos oscuros</label
                                >
                                <Uploader
                                  :preFiles="
                                    $filters.getFiles(
                                      data.f_logo_oscuro?.id_archivo
                                    )
                                  "
                                  :server="
                                    $filters.getServerConfiguracion(model)
                                  "
                                  v-model:file="data.f_logo_oscuro"
                                ></Uploader>
                              </div>
                            </div>

                            <div class="col-lg-6 col-md-6 col-sm-12">
                              <div class="form-group">
                                <label class="form-label"
                                  >Plantilla de la ficha técnica</label
                                >
                                <Uploader
                                  :preFiles="
                                    $filters.getFiles(
                                      data.f_ficha_tecnica?.id_archivo
                                    )
                                  "
                                  :server="
                                    $filters.getServerConfiguracion(model)
                                  "
                                  v-model:file="data.f_ficha_tecnica"
                                ></Uploader>
                              </div>
                            </div>

                            <div class="col-lg-6 col-md-6 col-sm-12">
                              <div class="form-group">
                                <label class="form-label"
                                  >Plantilla del reporte de empleados</label
                                >
                                <Uploader
                                  :preFiles="
                                    $filters.getFiles(
                                      data.f_reporte_empleados?.id_archivo
                                    )
                                  "
                                  :server="
                                    $filters.getServerConfiguracion(model)
                                  "
                                  v-model:file="data.f_reporte_empleados"
                                ></Uploader>
                              </div>
                            </div>

                            <div class="col-lg-6 col-md-6 col-sm-12">
                              <div class="form-group">
                                <label class="form-label"
                                  >Plantilla del reporte SISUB</label
                                >
                                <Uploader
                                  :preFiles="
                                    $filters.getFiles(
                                      data.f_sisub?.id_archivo
                                    )
                                  "
                                  :server="
                                    $filters.getServerConfiguracion(model)
                                  "
                                  v-model:file="data.f_sisub"
                                ></Uploader>
                              </div>
                            </div>

                            <div class="col-lg-6 col-md-6 col-sm-12">
                              <div class="form-group">
                                <label class="form-label"
                                  >Plantilla del reporte ICSOE</label
                                >
                                <Uploader
                                  :preFiles="
                                    $filters.getFiles(
                                      data.f_icsoe?.id_archivo
                                    )
                                  "
                                  :server="
                                    $filters.getServerConfiguracion(model)
                                  "
                                  v-model:file="data.f_icsoe"
                                ></Uploader>
                              </div>
                            </div>

                            <div class="col-lg-6 col-md-6 col-sm-12">
                              <div class="form-group">
                                <label class="form-label"
                                  >Plantilla del gaffete (Anverso)</label
                                >
                                <Uploader
                                  :preFiles="
                                    $filters.getFiles(
                                      data.f_gaffete_anverso?.id_archivo
                                    )
                                  "
                                  :server="
                                    $filters.getServerConfiguracion(model)
                                  "
                                  v-model:file="data.f_gaffete_anverso"
                                ></Uploader>
                              </div>
                            </div>
                            <div class="col-lg-6 col-md-6 col-sm-12">
                              <div class="form-group">
                                <label class="form-label"
                                  >Plantilla del gaffete (Reverso)</label
                                >
                                <Uploader
                                  :preFiles="
                                    $filters.getFiles(
                                      data.f_gaffete_reverso?.id_archivo
                                    )
                                  "
                                  :server="
                                    $filters.getServerConfiguracion(model)
                                  "
                                  v-model:file="data.f_gaffete_reverso"
                                ></Uploader>
                              </div>
                            </div>
                          </div>
                        </div>
                        <!-- .nk-block -->
                      </div>
                      <!-- .card-inner -->
                    </form>
                  </div>
                  <!-- .card-content -->
                </div>
                <!-- .card-aside-wrap -->
              </div>
              <!-- .card -->
            </div>
            <!-- .nk-block -->
          </div>
        </div>
      </div>
    </div>
    <!-- content @e -->
  </AppView>
</template>

<script>
import AppView from "@/components/sistema/AppView";
import { reactive, ref, toRefs, watch } from "vue";
import Swal from "sweetalert2";
import { useApi } from "@/use/useApi";
import Uploader from "@/components/globales/Uploader";

export default {
  name: "Editar Plataforma",
  components: { AppView, Uploader },
  setup() {
    const { loading, make } = useApi();

    const state = reactive({
      data: {},
      inited: false,
    });

    const model = ref({
      id: 1,
    });

    async function save() {
      loading.message = "Actualizando cuenta";
      let { data } = await make("configuracion/edit", {
        model: state.data,
      });
      if (data === null) {
        Swal.fire(
          "Error",
          "Ocurrió un error al conectarse con el servidor",
          "error"
        );
      } else {
        if (data.error) {
          Swal.fire("Error", data.message, "error");
        }
      }
    }

    async function get() {
      loading.message = "Obteniendo información";
      let { data } = await make("configuracion/get", {
        model: state.data,
      });
      if (data === null) {
        Swal.fire(
          "Error",
          "Ocurrió un error al conectarse con el servidor",
          "error"
        );
      } else {
        if (data.error) {
          Swal.fire("Error", data.message, "error");
        } else {
          state.data = data.model;
        }
      }
    }

    watch(
      () => state.data,
      () => {
        if (state.inited) {
          save();
        } else {
          state.inited = true;
        }
      },
      { deep: true }
    );

    get();

    return { ...toRefs(state), model, loading, save, get };
  },
};
</script>

<style scoped>
.profile-ud-list {
  max-width: inherit;
}
.profile-ud-label {
  width: 130px;
  align-items: start;
  margin-top: 0.5em;
}
.profile-ud-value {
  line-height: 2.5em;
}
</style>